import React, { useEffect } from 'react';

import Str from '../../../helpers/Str';

import Navbar from '../../../components/nav/Navbar';
import Button from "../../button/Button";
import {BsExclamationLg} from "react-icons/bs";
import './index.css';
import {useNavigate} from "react-router";
export interface PageContainerProps {
    children: React.ReactNode;
    className?: string;
    menuTitle?: string;
    pageTitle?: string;
    innerClassName?: string;
    footer?: boolean;
}

export default function PageContainer({
  children,
  className,
  innerClassName,
  menuTitle,
  pageTitle,
  footer,
  ...rest
}: PageContainerProps) {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = pageTitle || menuTitle || 'Milagre dos peixes';
    }, []);


    return (
        <div {...rest} className={Str.tw(
            className,
        )}>
            <Navbar pageTitle={pageTitle || menuTitle} />
            {/*<AsideMenu menuTitle={menuTitle} />*/}

            <main className={Str.tw(innerClassName, 'grid container mx-auto lg:px-[2rem] min-[320px]:px-1')}>
                {children}
            </main>

            {footer && <div className={'marginTopCalc'}>
                <footer className="dark:bg-gray-800 ">
                    <div className="w-full mx-auto p-2 md:flex md:items-center md:justify-end">
                        <ul className="flex flex-wrap items-center mt-3 font-outline-2 text-xl outline-font sm:mt-0">
                            <li>
                                <Button className="mr-2  md:mr-4 " onClick={() => navigate('/salas')} >Salas</Button>
                                {/*<a href="#" className="mr-4 hover:underline md:mr-6 ">About</a>*/}
                            </li>
                            <li>
                                <Button className="mr-2 md:mr-4" onClick={() => navigate('/alunos-cursos')}>Contratos</Button>
                            </li>
                            {/* <li>
                                <button
                                    className="rounded-md p-4 border justify-center text-white bg-criatividade hover:bg-criatividade-100 text-xl">
                                    <BsExclamationLg size={'22px'} />
                                </button>
                            </li> */}
                        </ul>
                    </div>
                </footer>
            </div>}
        </div>
    );
}
