import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { Button, FormContainer } from "../../../components";
import MatriculaAlunoForm from "../../../components/form/MatriculaAlunoForm";
import { Aluno, AlunoCurso, Curso } from "../../../models";
import { FormGroupType } from ".";
import { ISelect } from "../../../types";

export type Step1Props = {
  matricula?: string;
  aluno: { loading?: boolean; alunos: Aluno[]; responsavelCPF?: string; };
  curso: { loading?: boolean; cursos: Curso[]; };
  materiaisAsSelectOptions: ISelect[];
  form: FormGroupType;
  events: {
    onNext: () => any;
    onAddMaterial: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
    onRemoveMaterial: (i: number, j: number, alunoCurso: Partial<AlunoCurso>) => any;
    getValorMaterial: (materialId: number) => number;
  }
}

export default function Step1(props: Step1Props) {
  return (
    <FormContainer title="Cadastrar cursos para aluno" onSubmit={props.events.onNext} key={props.form.form.aluno_cursos?.length}>
      <div className="grid gap-6 md:grid-cols-2">
        {(props.form.form.aluno_cursos ?? []).map((alunoCurso, i) => (
          <div>
            <header className="flex items-center justify-between">
              {(props.form.form.aluno_cursos?.length ?? 0) > 1 && <button 
                type='button' 
                title="Adicionar Material"
                className="mt-5 ml-auto -mb-10"
                onClick={() => props.form.removeAlunoCurso(i, alunoCurso)}
              >
                  <FiMinusCircle color={'darkred'} size={30}/>
              </button>}
            </header>

            <MatriculaAlunoForm 
              key={i}
              matricula={props.matricula}
              alunoCursoIndex={i}
              alunoCurso={alunoCurso}
              errors={props.form.getAlunoCursoErrors(i)}
              materiaisAsSelectOptions={props.materiaisAsSelectOptions}
              setAlunoCurso={props.form.setAlunoCurso}
              onAddMaterial={props.events.onAddMaterial}
              onRemoveMaterial={props.events.onRemoveMaterial}
              getValorMaterial={props.events.getValorMaterial}
              aluno={{
                isLoading: props.aluno.loading,
                options: props.aluno.alunos,
                responsavelCPF: i === 0 ? undefined : props.aluno.responsavelCPF,
              }}
              curso={{
                isLoading: props.curso.loading,
                options: props.curso.cursos,
              }}
            />
          </div>

        ))}

        {!props.matricula && <div className="flex flex-col items-center justify-center ">
          <button className="flex flex-col items-center justify-center hover:animate-pulse" type="button" onClick={() => props.form.addAlunoCurso() }>
            <FiPlusCircle color={'#30694A'} size={30}/>
            <span>Adicionar Curso</span>
          </button>
        </div>}
      </div>

      <footer className="flex items-center justify-between mt-10">
        <div>
          <small>Campos com <span className="text-red-500">*</span> são obrigatórios</small>
        </div>

        <Button
          loading={props.form.isLoading} 
          variant="confianca"
          type="submit"
        >
          Próximo
        </Button>
      </footer>
    </FormContainer>
  );
}
