import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import PageContainer from "../../components/container/PageContainer";
import useModal from "../../hooks/useModal";
import Modal from "../../components/modal/Modal";
import CheckCategorias from "../../components/checkCategorias";
import SelectDias from "../../components/selectDias";
import Form from "../../components/form/Form";
import Input from "../../components/input/Input";
import InputSelect from "../../components/inputSelect";
import turnosApi from "../../services/turnosApi";
import CardButton from "../../components/cardButton";
import salasApi from "../../services/salasApi";
import Alert from "../../helpers/Alert";
import Table from "../../components/table/Table";
import useTable from "../../hooks/useTable";
import cursosApi from "../../services/cursosApi";
import diasApi from "../../services/diasApi";
import TabsCategoria from "../../components/tabsCategorias";
import categoriasApi from "../../services/categoriaApi";
import { Select } from "../../components";
import Str from "../../helpers/Str";
import { Filr } from "../../helpers";

export default function Salas() {
  const navigate = useNavigate();
  const { modalProps, open, close } = useModal();

  const [modalStep, setModalStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [especificarTurnos, setEspecificarTurnos] = useState(false);
  const [state, setState]: any[] = useState([]);
  const [optionsSelectTurnos, setOptionsSelectTurnos]: any[] = useState([]);
  const [optionsSelect, setOptionsSelect]: any[] = useState();

  const [selectedCategoria, setSelectedCategoria] = useState(1);
  const [selectedDias, setSelectedDias]: any[] = useState([]);
  const [arraySelectedDias, setArraySelectedDias]: any[] = useState([]);

  const [optionsCursos, setOptionsCursos]: any[] = useState();
  const [selectedCursos, setSelectedCursos]: any[] = useState([]);

  const [categorias, setCategorias]: any[] = useState([]);
  const [categoriaId, setCategoriaId]: any = useState(1);
  const [cursos, setCursos]: any[] = useState([]);

  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [pavimento, setPavimento] = useState<string>("TÉRREO");

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  function handleChange(value: string | number | boolean, name: any) {
    setState({ ...state, [name]: value });
  }

  const [dias, setDias] = useState(diasApi);

  const onChangeSelect = (e: any, id: number) => {
    const array = [...arraySelectedDias];
    const val = array.findIndex((sd: { id: number }) => sd.id === id);
    array[val].turnos = e;
    if (e.length > 1) {
      const index = array[val].turnos.findIndex(
        (sd: { label: string }) => sd.label === "Todos"
      );
      if (index >= 0) {
        array[val].turnos = e.filter((ef: any) => ef.value !== e[index].value);
      }
    }
    setOptionsSelect(e);
  };

  async function getData() {
    const [turnosData, cursosData, categoriasData] = await Promise.all([
      turnosApi.listAll(),
      cursosApi.listAll(),
      categoriasApi.listAll(),
    ]);
    if (!turnosData.isError) {
      const optTurnos = turnosData.data.map((trDt: any) => {
        return {
          value: trDt.id,
          label: trDt.nome === "Todos" ? trDt.nome : trDt.nome.substring(3, -5),
        };
      });
      setOptionsSelectTurnos(optTurnos);
    }
    if (!cursosData.isError) {
      const cursoFilter = cursosData.data.filter(
        (filt: any) => filt.categoria_id === categoriaId
      );
      const optCursos = cursoFilter.map((cr: any) => {
        return {
          value: cr.id,
          label: `${cr.descricao} / ${cr.categoria.nome}`,
        };
      });
      setCursos(cursosData.data);
      setOptionsCursos(optCursos);
    }
    if (!categoriasData.isError) {
      setCategorias(categoriasData.data);

      // @ts-ignore
      setCategoriaId(categoriasData.data[0]["id"]);
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const limpaDados = () => {
    setArraySelectedDias([]);
    setSelectedDias([]);
    setState([]);
    setSelectedCursos([]);
    setSelectedCategoria(categoriaId);
    setEspecificarTurnos(false);
    const newDias = [...dias];
    newDias.map((nd: any) => (nd.turnos = []));
    setDias(newDias);
    setPavimento("TÉRREO");
    setImage(null);
    setImagePreview(null);
    setModalStep(1);
  };

  const onSubmit = async () => {
    const plantaBaixaBase64 = image
      ? await Filr.convertFileToBase64(image)
      : null;

    const finalState = {
      ...state,
      todos_turnos: especificarTurnos,
      categoria_id: selectedCategoria,
      disponibilidades: arraySelectedDias,
      pavimento,
      plantaBaixaBase64,
    };

    const { data, isError } = state.id
      ? await salasApi.update(state.id, finalState)
      : await salasApi.create(finalState);

    if (isError) {
      if (typeof data === "object") {
        Object.keys(data).forEach(function (key, index) {
          Alert.error(data[key][0]);
        });
        return;
      }
      return Alert.error(data.message ? data.message : data);
    }
    const mensage = state.id
      ? "Editado com sucesso!"
      : "Cadastrado com sucesso!";
    Alert.success(mensage);
    close();
    limpaDados();
    //atualizar tabela aqui
    await onSearch("");
  };

  const { tableProps, onSearch } = useTable({
    dataLoader: async (params) => {
      if (params) {
        params["where"] = `categoria_id,=,${categoriaId}`;
        params[
          "relations"
        ] = `salasDisponibilidades.disponibilidadeTurnos,cursos`;
      }
      const { data, isError } = await salasApi.all(params);
      setLoading(false);
      return isError ? false : data;
    },
  });

  const columns = [
    { title: "Id", field: "id" },
    { title: "Nome", field: "nome" },
    { title: "codigo", field: "codigo" },
  ];

  const onEdit = (salasData: any) => {
    setSelectedCategoria(salasData.categoria_id);
    setEspecificarTurnos(salasData.todos_turnos);
    setState({
      id: salasData.id,
      nome: salasData.nome,
      codigo: salasData.codigo,
    });
    const salaSelec = salasData.cursos.map((cur: any) => {
      return {
        value: cur.id,
        label: `${cur.descricao} / ${cur.categoria.nome}`,
      };
    });
    setSelectedCursos(salaSelec);
    let idsDiasArray: any[] = [];
    salasData.salas_disponibilidades.map((slDisp: any) => {
      idsDiasArray.push(slDisp.dia);
    });
    setSelectedDias(idsDiasArray);
    selectDias(salasData.salas_disponibilidades);
    setPavimento(salasData.pavimento);
    setImagePreview(
      `${process.env.REACT_APP_API}/salas/get-image?path=${salasData.planta_baixa}`
    );
    open();
  };

  const onDelete = async (salasData: any) => {
    const confirm = await Alert.confirm(
      "Tem certeza que deseja excluir?",
      "Excluir sala?"
    );
    if (!confirm) return;
    Alert.await("Excluindo sala...");
    const { data, isError } = await salasApi.destroy(salasData.id);
    if (isError) {
      if (typeof data === "object") {
        Object.keys(data).forEach(function (key, index) {
          Alert.error(data[key][0]);
        });
        return;
      }
      return Alert.error(data.message ? data.message : data);
    }
    Alert.close();
    await onSearch("");
  };

  const onOpenModalForm = () => {
    limpaDados();
    open();
  };

  const onSelectCurso = (e: any, input: string) => {
    const arrayIds = e.map((d: any) => d.value);
    setState({ ...state, [input]: arrayIds });
    setSelectedCursos(e);
  };

  const onSelectDias = (e: any) => {
    setSelectedDias(e);
    selectDias(e);
  };

  const selectDias = (diasIds: any) => {
    let dataDiasSelect: any = [];

    if (Number.isInteger(diasIds[0])) {
      const arraySelected = [...arraySelectedDias];
      let newArray: any[] = [];
      if (arraySelected.length) {
        diasIds.map((slD: any) => {
          const d = dias.filter((d) => d.id === slD)[0];
          const findArr = arraySelected.find((d) => d.id === slD);

          if (findArr === undefined) {
            if (!d?.turnos.length) {
              // @ts-ignore
              d.turnos.push(optionsSelectTurnos[0]);
            }
            arraySelected.push(d);
            newArray = arraySelected;
          } else {
            newArray.push(findArr);
          }
        });
        dataDiasSelect = newArray;
      } else {
        diasIds.filter((slD: any) => {
          let diasSl = dias.filter((d) => d.id === slD)[0];
          if (diasSl.turnos.length) {
            diasSl.turnos = [];
          }
          // @ts-ignore
          diasSl.turnos.push(optionsSelectTurnos[0]);

          dataDiasSelect.push(diasSl);
        });
      }
    } else {
      diasIds.map((ds: any) => {
        let diasSl = dias.filter((d) => d.id === ds.dia)[0];

        if (diasSl.turnos.length) {
          diasSl.turnos = [];
        }
        ds.disponibilidade_turnos?.map((tur: any) => {
          const turno = optionsSelectTurnos.filter(
            (optT: any) => optT.value === tur.id
          )[0];

          // @ts-ignore
          diasSl.turnos.push(turno);
        });
        dataDiasSelect.push(diasSl);
      });
    }
    setArraySelectedDias(dataDiasSelect);
  };

  useEffect(() => {
    onSearch("");
  }, [categoriaId]);

  const selectCategoriaId = (datacategoriaId: number) => {
    const optCursos = filterCursosCategoria(datacategoriaId);
    setOptionsCursos(optCursos);

    setLoading(true);
    setSelectedCategoria(datacategoriaId);
    setCategoriaId(datacategoriaId);
    setLoading(false);
  };

  const filterCursosCategoria = (e: any) => {
    const cursosData = [...cursos];
    let newArrayCursos: any[] = [];
    cursosData.map((cd: any) => {
      if (cd.categoria_id === e) {
        newArrayCursos.push(cd);
      }
    });
    return newArrayCursos.map((cr: any) => {
      return {
        value: cr.id,
        label: `${cr.descricao} / ${cr.categoria.nome}`,
      };
    });
  };

  const onSelectCategoria = (e: any) => {
    const optCursos = filterCursosCategoria(e);
    setOptionsCursos(optCursos);
    setSelectedCategoria(e);
  };

  return (
    <>
      <PageContainer>
        <div className={"flex flex-col"}>
          <div className={"flex flex-row justify-between p-2 w-full"}>
            <h1 className={"text-3xl flex items-center"}>SALAS - RESUMO</h1>
            <Button
              className={"bg-constancia hover:bg-constancia-100 text-white"}
              onClick={() => onOpenModalForm()}
            >
              Novo
            </Button>
          </div>
          <div className={"flex flex-row  p-2 w-full"}>
            <TabsCategoria
              categorias={categorias}
              setCategoriaId={selectCategoriaId}
            />
            <div className={"flex flex-col"}>
              <div className={"flex flex-row  p-2 w-full mt-2"}>
                <Button
                  className={
                    "bg-conhecimento hover:bg-conhecimento-100 text-white"
                  }
                  onClick={() => navigate("/salas/disponibilidades")}
                >
                  Disponibilidades
                </Button>
              </div>
            </div>
          </div>
          {/* <div className={'flex flex-row  p-2 w-full'}>
                        <Button className={'bg-conhecimento hover:bg-conhecimento-100 text-white'} onClick={() => navigate('/lembretes/cadastro')}>Buscar</Button>
                    </div> */}
        </div>

        <div className={"mt-5 "}>
          <Table
            loading={loading}
            columns={columns}
            data={tableProps.data}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
      </PageContainer>
      {/*Modal de cadastro e edição*/}
      <Modal size="lg" title="CADASTRAR NOVA SALA" {...modalProps}>
        {loading ? (
          <span className="flex items-center justify-center w-full">
            <svg
              className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        ) : (
          <fieldset className="mt-1">
            <Form
              onSubmit={() => {
                onSubmit();
              }}
            >
              {modalStep === 1 ? (
                <>
                  <div className="grid grid-cols-2 gap-4">
                    <Input
                      label="Nome"
                      className="col-span-1"
                      value={state?.nome}
                      setValue={(v) => handleChange(v, "nome")}
                    />
                    <Input
                      label="Código"
                      className="col-span-1"
                      value={state?.codigo}
                      setValue={(v) => handleChange(v, "codigo")}
                    />
                  </div>

                  <CheckCategorias
                    value={selectedCategoria}
                    onChange={onSelectCategoria}
                    categorias={categorias}
                  />
                  <div className={"grid grid-cols-1"}>
                    <InputSelect
                      selected={selectedCursos}
                      loading={loading}
                      options={optionsCursos}
                      onChangeSelect={(e: any) => onSelectCurso(e, "cursos")}
                      placeholder={"Cursos"}
                      isMulti={true}
                    />
                  </div>

                  <SelectDias
                    selectedDias={selectedDias}
                    setSelectedDias={onSelectDias}
                  />

                  <div className="flex items-center mt-4">
                    <input
                      onClick={() => setEspecificarTurnos(!especificarTurnos)}
                      checked={especificarTurnos}
                      id="checked-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checked-checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Especificar turno?
                    </label>
                  </div>
                  {especificarTurnos ? (
                    <div /*className={'max-h-60 overflow-auto'}*/>
                      {arraySelectedDias.length
                        ? arraySelectedDias.map((aSD: any) => {
                            return (
                              <div className="grid grid-cols-6 gap-4">
                                <div className={"col-span-1 flex items-end"}>
                                  <CardButton
                                    text={aSD.abreviacao}
                                    onSelect={() => {
                                      ("");
                                    }}
                                  />
                                </div>

                                <div className={"col-span-5 items-start"}>
                                  <InputSelect
                                    selected={aSD.turnos}
                                    loading={loading}
                                    options={optionsSelectTurnos}
                                    onChangeSelect={(e: any) =>
                                      onChangeSelect(e, aSD.id)
                                    }
                                    placeholder={"Turnos"}
                                    isMulti={true}
                                  />
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <Select
                    required
                    label="Pavimento"
                    className="col-span-full lg:col-span-4"
                    value={pavimento}
                    onChangeSelect={(data: any) => {
                      setPavimento(data.value);
                    }}
                    isMulti={false}
                    options={[
                      { value: "TÉRREO", label: "TÉRREO" },
                      {
                        value: "PRIMEIRO PAVIMENTO",
                        label: "PRIMEIRO PAVIMENTO",
                      },
                      {
                        value: "SEGUNDO PAVIMENTO",
                        label: "SEGUNDO PAVIMENTO",
                      },
                    ]}
                  />

                  <label
                    style={{ display: "flex", flexDirection: "column" }}
                    className="my-6"
                  >
                    <span>Planta baixa</span>
                    <input
                      type="file"
                      className={Str.tw(
                        "py-2",
                        "bg-light border border-border rounded-md outline-none px-3 lg:px-2 w-full",
                        "focus:bg-background focus:border-confianca transition uppercase"
                      )}
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </label>

                  <div style={{}}>
                    <h3>Miniatura:</h3>
                    {imagePreview && <img src={imagePreview} alt="Miniatura" />}
                  </div>
                </>
              )}

              <div className="flex justify-end mt-4">
                {modalStep === 1 && (
                  <Button
                    type={"button"}
                    onClick={() => setModalStep(2)}
                    className="bg-constancia hover:bg-constancia-100 text-white"
                  >
                    Próximo
                  </Button>
                )}
                {modalStep === 2 && (
                  <Button type={"button"} onClick={() => setModalStep(1)}>
                    Voltar
                  </Button>
                )}
                {modalStep === 2 && (
                  <Button
                    type={"submit"}
                    className="bg-constancia hover:bg-constancia-100 text-white ml-4"
                  >
                    Salvar
                  </Button>
                )}
              </div>
            </Form>
            {/*especificar turno*/}
          </fieldset>
        )}
      </Modal>
    </>
  );
}
