import { useState } from "react";
import { ListPageLayout, ClausulaForm } from "../../components";
import clausulaApi from "../../services/clausulaApi";

export default function Clausulas() {
  const [id, setId] = useState<number>();

  return (
    <ListPageLayout
      title="Cláusulas"
      deletion={{ handler: clausulaApi.destroy }}
      registerModal={{
        form: <ClausulaForm id={id} />,
        onOpen: (row) => setId(row?.id),
      }}
      table={{
        loader: clausulaApi.all,
        columns: [
          { title: "Id", field: "id" },
          { title: "Ordem", field: "ordem" },
          { title: "Nome", field: "nome" },
        ],
      }}
    />
  );
}
