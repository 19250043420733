import { useClausulaForm } from "../../../hooks/useClausulaForm";
import Form from "../Form";
import Input from "../../input/Input";
import { Button, RichText, Select } from "../..";

export default function ClausulaForm(props: {
  id?: number;
  onSubmitSuccess?: () => void;
}) {
  const { register, save, cursos, loading } = useClausulaForm({
    id: props.id,
    onSubmitSuccess: props.onSubmitSuccess,
  });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input
        required
        label="Nome"
        className="col-span-full md:col-span-9"
        {...register("nome")}
      />

      <Input
        required
        type="number"
        label="Ordem"
        className="col-span-full md:col-span-3"
        {...register("ordem")}
      />

      <Select
        label="Cursos"
        loading={cursos.loading}
        options={cursos.asSelectOptions}
        placeholder={"Cursos"}
        className="col-span-full"
        {...register("cursos_ids")}
        isMulti
      />

      <RichText
        required
        label="Descrição (Informação que aparecerá no contrato)"
        className="col-span-full"
        {...register("descricao")}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit" loading={loading}>
          Cadastrar
        </Button>
      </footer>
    </Form>
  );
}
