import dayjs from "dayjs";
import { Input, Select, Txt } from "../..";
import { alunoCursoMonthlyValue, alunoCursoTurnosHorarios, diasSemanaFromProfessor } from "../../../functions";
import { Aluno, AlunoCurso, Curso, Professor } from "../../../models";
import { useEffect, useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { ISelect } from "../../../types";
import InputCurrency from "../../inputCurrency";

export type AlunoCursoFormType = Partial<Omit<Aluno, 'aluno_cursos'>> & {
  aluno_cursos?: Partial<AlunoCurso>[];
}

export type AlunoCursoErrors = { 
  aluno_id?: string;
  curso_id?: string;
  professor_temp?: string;
  horarios?: string;
  dias_semana_temp?: string;
  planos?: string;
  carga_horaria?: string;
  data_inicio?: string;
  repasse?: string;
  obs?: string;
  pagamento_dia?: string;
  pagamento_tipo_notificacao?: string;
  pagamento_forma?: string;
};

export type MatriculaAlunoFormProps = {
  alunoCursoIndex: number;
  alunoCurso: Partial<AlunoCurso>;
  errors?: null | AlunoCursoErrors;
  matricula?: string;
  materiaisAsSelectOptions: ISelect[];
  setAlunoCurso: (alunoCursoIndex: number, alunoCurso: Partial<AlunoCurso>) => any;
  onAddMaterial: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
  onRemoveMaterial: (i: number, j:   number, alunoCurso: Partial<AlunoCurso>) => any;
  getValorMaterial: (materialId: number) => number;
  aluno: {
    isLoading?: boolean;
    isDisabled?: boolean;
    responsavelCPF?: string;
    options: Aluno[];
  },
  curso: {
    isLoading?: boolean;
    isDisabled?: boolean;
    options: Curso[];
  },
}

export default function MatriculaAlunoForm(props: MatriculaAlunoFormProps) {
  const [paymentDays, setPaymentDays] = useState<{ value: number, label: number}[]>([]);

  useEffect(() => {
    handleDataInicioChange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alunoCurso.data_inicio]);

  function handleDataInicioChange() {
    const days = daysOfMonth(props.alunoCurso.data_inicio);
    const lastDay = days?.length ? days[days.length - 1].value : 0;
    setPaymentDays(days ?? []);
    if (lastDay < (props.alunoCurso.pagamento_dia ?? 0)) setPagamentoDia(lastDay);
  }

  function daysOfMonth(startDateValue?: string) {
    const startDate = startDateValue ? dayjs(startDateValue).daysInMonth() : 28;
    return Array.from({ length: startDate }, (_, i) => ({ value: i+1,   label: i+1}));
  } 

  function setAluno(aluno?: null | number | Aluno) {
    const alunoId = (typeof aluno === 'object' ? aluno?.id : aluno as number);
    const foundAluno = !!alunoId ? props.aluno.options.find(al => al.id == alunoId) : undefined;
    return props.setAlunoCurso(props.alunoCursoIndex, {
      ...props.alunoCurso, 
      aluno_id: foundAluno?.id as any, 
      aluno: foundAluno
    });
  }

  function setCurso(curso?: null | number | Curso) {
    const cursoId = (typeof curso === 'object' ? curso?.id : curso as number);
    const foundCurso = !!cursoId ? props.curso.options.find(al => al.id == cursoId) : undefined;
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      curso_id: foundCurso?.id as any, 
      curso: foundCurso
    });
  }

  function setProfessor(professor?: null | number | Professor) {
    const professorId = (typeof professor === 'object' ? professor?.id : professor as number);
    const foundProfessor = !!professorId ? (props.alunoCurso.curso?.professores ?? []).find(al => al.id == professorId) : undefined;
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso,
      professor_temp: foundProfessor
    });
  }

  function setDiasSemana(value: number[]) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso,
      dias_semana_temp: value
    });
  }

  function setHorarios(value: number[]) {
    const horarios: Partial<AlunoCurso['horarios']> = [];

    value.forEach((salaTurnoId: number) => {
        horarios.push({
          salas_turnos_id: salaTurnoId
        } as any);
    });
    
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      horarios: horarios as any
    });
  }

  function setPlano(plano: ('Mensal' | 'Trimestral' | 'Semestral' | 'Outro')[]) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      planos: plano
    });
  }

  function setDataInicio(data_inicio: string) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      data_inicio
    });
  }

  function setCargaHoraria(carga_horaria: string) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      carga_horaria
    });
  }

  function setPagamentoDesconto(porcentagem_desconto: string) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      porcentagem_desconto
    });
  }

  function setPagamentoDia(pagamento_dia: number) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      pagamento_dia
      // meses_pagamento: (alunoCurso.meses_pagamento ?? []).map(item => {
      //   const splitted = item.split('-');
      //   const year = splitted[0];
      //   const month = splitted[1];
      //   const day = `${newValue}`.length === 1 ? `0${newValue}` : `${newValue}`;
      //   return `${year}-${month}-${day}`;
      // })
    });
  }

  function setFormaPagamento(pagamento_forma: "Pix" | "Boleto" | "Cartão" | "Dinheiro") {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      pagamento_forma
    });
  }

  function setTipoNotificacao(pagamento_tipo_notificacao: "WhatsApp" | "E-mail" | "SMS") {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      pagamento_tipo_notificacao
    });
  }

  function setRepasse(repasse?: "espaco" | "professor") {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      repasse
    });
  }

  function setObs(obs?: string) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      obs
    });
  }

  function setMaterialId(i: number, materialId: number) {
    return props.setAlunoCurso(props.alunoCursoIndex, { 
      ...props.alunoCurso, 
      materiaisRepasse: (props.alunoCurso.materiaisRepasse ?? []).map((material, j) => i === j ? { ...material, material_id: materialId } : material)
    });
  }

  function setRepasseMaterial(i: number, quem_paga: "espaco" | "professor") {
    return props.setAlunoCurso(props.alunoCursoIndex, {
      ...props.alunoCurso,
      materiaisRepasse: (props.alunoCurso.materiaisRepasse ?? []).map((material, j) => i === j ? { ...material, quem_paga } : material)
    });
  }

  return (
    <div className="grid grid-cols-6 gap-4 mt-8">
      <Select
        label="Aluno"
        required
        disabled={!!props.matricula}
        className="col-span-full"
        loading={props.aluno.isLoading}
        isDisabled={props.aluno.isDisabled}
        value={props.alunoCurso.aluno_id}
        setValue={setAluno}
        options={
          props.aluno.options
            .filter(aluno => !!props.aluno.responsavelCPF ? aluno.responsavel_cpf == props.aluno.responsavelCPF : true)
            .map((aluno) => ({ value: aluno.id, label: aluno.nome }))
        }
        error={props.errors?.aluno_id}
      />

      <Select
        label="Curso"
        required
        loading={props.curso.isLoading}
        isDisabled={props.curso.isDisabled}
        value={props.alunoCurso.curso_id}
        setValue={setCurso}
        className="col-span-full"
        disabled={!!props.matricula}
        options={props.curso.options.map((curso) => ({ value: curso.id, label: curso.descricao }))}
        error={props.errors?.curso_id}
      />

      <Select
        label="Professor"
        className="col-span-full"
        required
        disabled={!!props.matricula}
        value={props.alunoCurso.professor_temp?.id}
        setValue={setProfessor}
        options={props.alunoCurso.curso?.professores?.map(professor => ({ value:professor.id, label: professor.nome })) ?? []}
        error={props.errors?.professor_temp}
      />

      <Select
        label="Dias(s) da Semana"
        className="col-span-full"
        isMulti
        required
        disabled={!!props.matricula}
        error={props.errors?.dias_semana_temp}
        value={props.alunoCurso.dias_semana_temp}
        setValue={setDiasSemana}
        options={diasSemanaFromProfessor(props.alunoCurso.professor_temp)}
      />

      <Select
        label="Horário"
        isMulti
        required
        disabled={!!props.matricula}
        className="col-span-full"
        error={props.errors?.horarios}
        value={props.alunoCurso.horarios?.map((h) => h.salas_turnos_id)}
        setValue={setHorarios}
        options={alunoCursoTurnosHorarios(props.alunoCurso)}
      />

      <Select
        label="Plano"
        required
        disabled={!!props.matricula}
        className="col-span-full"
        value={Array.isArray(props.alunoCurso.planos) ? props.alunoCurso.planos?.length ? props.alunoCurso.planos[0] : undefined : props.alunoCurso.planos}
        setValue={(v: any) => setPlano(Array.isArray(v) ? v : [v])}
        error={props.errors?.planos}
        options={[
          {value: 'Mensal', label: 'Mensal'}, 
          {value: 'Trimestral', label: 'Trimestral'}, 
          {value: 'Semestral', label: 'Semestral'}, 
        ]}
      />

      <Input 
        label="Início"
        required
        type="date"
        disabled={!!props.matricula}
        data-inicio={props.alunoCurso.id}
        className="col-span-full lg:col-span-3"
        value={props.alunoCurso.data_inicio}
        setValue={setDataInicio}
        error={props.errors?.data_inicio}
      />

      <Input
        label="Carga Horária"
        className="col-span-full lg:col-span-3"
        disabled={!!props.matricula}
        value={props.alunoCurso.carga_horaria}
        setValue={setCargaHoraria}
        error={props.errors?.carga_horaria}
      />

      <Select 
        label="Dia Pagamento"
        required
        disabled={!!props.matricula}
        className="col-span-full lg:col-span-2"
        value={props.alunoCurso?.pagamento_dia}
        error={props.errors?.pagamento_dia}
        setValue={setPagamentoDia}
        options={paymentDays}
      />

      <Select 
        label="Forma Pagamento"
        required
        disabled={!!props.matricula}
        className="col-span-full lg:col-span-2"
        value={props.alunoCurso.pagamento_forma}
        error={props.errors?.pagamento_forma}
        setValue={setFormaPagamento}
        options={[
            {value: 'Pix',      label: 'Pix'},
            {value: 'Boleto',   label: 'Boleto'},
            {value: 'Cartão',   label: 'Cartão'},
            {value: 'Dinheiro', label: 'Dinheiro'},
        ]}
      />
    
      <Select 
        label="Notificar"
        required
        disabled={!!props.matricula}
        className="col-span-full lg:col-span-2"
        error={props.errors?.pagamento_tipo_notificacao}
        value={props.alunoCurso.pagamento_tipo_notificacao}
        setValue={setTipoNotificacao}
        options={[
            {value: 'WhatsApp', label: 'WhatsApp'},
            {value: 'E-mail',   label: 'E-mail'},
            {value: 'SMS',      label: 'SMS'},
        ]}
      />

      <Input 
        label="Valor/Mês"
        disabled
        type="number"
        className="col-span-full lg:col-span-2"
        value={alunoCursoMonthlyValue(props.alunoCurso)}
      />
  
      <Input 
        label="% Desconto"
        className="col-span-full lg:col-span-2"
        type="number"
        disabled={!!props.matricula}
        value={props.alunoCurso.porcentagem_desconto}
        setValue={setPagamentoDesconto}
      />
  
      <Input 
        label="Valor/Mês Final"
        disabled
        type="number"
        className="col-span-full lg:col-span-2"
        value={alunoCursoMonthlyValue(props.alunoCurso, true)}
      />

      <Select
        label="Descontar no pagamento"
        className="col-span-full"
        value={props.alunoCurso.repasse}
        setValue={setRepasse}
        error={props.errors?.repasse}
        disabled={!!props.matricula}
        options={[
            
            {
                value: 'espaco',
                label: 'Espaço'
            },
            {
                value: 'professor',
                label: 'Professor'
            },
        ]}
      />

      <Input
        label="Observação"
        className="col-span-full"
        value={props.alunoCurso.obs}
        setValue={setObs}
        disabled={!!props.matricula}
        error={props.errors?.obs}
      />

      {/* <div className="col-span-full mt-4">
        <header className="flex items-center gap-4 col-span-full">
          <Txt variant="title">Materiais</Txt>
  
          {!props.matricula && <button 
            type='button' 
            title="Adicionar Material" 
            onClick={() => props.onAddMaterial(props.alunoCursoIndex, props.alunoCurso)}
          >
              <FiPlusCircle color={'#30694A'} size={30}/>
          </button>}
        </header>
  
        {props.alunoCurso.materiaisRepasse?.map((material, i) => <div key={i} className="col-span-full grid grid-cols-12 gap-4">
          <Select
            label='Material'
            disabled={!!props.matricula}
            required
            className={'col-span-full lg:col-span-5'}
            options={props.materiaisAsSelectOptions}
            value={material.material_id}
            setValue={(v: any) => setMaterialId(i, v as number)}
          />
  
          <InputCurrency 
            label="Valor"
            onChange={() => {}}
            className="col-span-full lg:col-span-2"
            value={props.getValorMaterial(material.material_id)}
          />
  
          <Select
            label="Repasse"
            disabled={!!props.matricula}
            required
            className="col-span-full lg:col-span-4"
            value={material.quem_paga}
            setValue={(value: "espaco" | "professor") => setRepasseMaterial(i, value)}
            options={[
              { value: 'espaco', label: 'Espaço' },
              { value: 'professor', label: 'Professor' }]}
          />
  
        {!props.matricula && <button 
            type='button' 
            title="Adicionar Material"
            className="mt-5"
            onClick={() => props.onRemoveMaterial(i, props.alunoCursoIndex, props.alunoCurso)}
          >
              <FiMinusCircle color={'darkred'} size={30}/>
          </button>}
        </div>)}
      </div> */}
    </div>
  );  
}
