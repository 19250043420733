import { PaginatedData } from "../../../types";
import Str from "../../../helpers/Str";
import BaseTable from "../BaseTable";
import Pagination from "../../nav/Pagination";

export interface TableColumn1 {
  title: string;
  field: string;
  width?: number;
  columnType?:
    | "phone"
    | "CNPJ"
    | "CPF"
    | "status"
    | "reversedStatus"
    | "YesOrNo"
    | "date"
    | "money";
}

export interface TableProps {
  className?: string;
  columns: any;
  data?: PaginatedData;
  onPageChange?: (page: number) => void;
  onPerPageChange?: (perPage: number) => void;
  onItemClick?: (item: any) => void;
  loading?: boolean;
  onEdit?: (item: any) => void;
  onDelete?: (item: any) => void;
  unpaginated?: boolean;
}

export default function Table({
  className,
  columns,
  data,
  onPageChange,
  onPerPageChange,
  onItemClick,
  loading,
  onEdit,
  onDelete,
  unpaginated = false,
}: TableProps) {
  return loading ? (
    <span className="flex items-center justify-center h-7">
      <svg
        className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
  ) : (
    <div className={Str.tw(className, "border rounded-lg bg-background")}>
      <BaseTable
        columns={columns}
        data={data?.data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      {!unpaginated && (
        <footer className="py-3 px-5 border-t flex items-center justify-between flex-wrap gap-5">
          <div>
            {!!data && (
              <Pagination
                currentPage={data.current_page}
                lastPage={data.last_page}
                onPageChange={onPageChange}
              />
            )}
          </div>
        </footer>
      )}
    </div>
  );
}
