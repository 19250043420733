import { PageContainer, useStep, Step,  } from "../../../components";
import { AlunoCurso } from "../../../models";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { useAlunos, useCursos, useForm, useMateriais } from "../../../hooks";
import { z } from "zod";
import { AlunoCursoErrors, AlunoCursoFormType } from "../../../components/form/MatriculaAlunoForm";
import { alunoCursoAmountOfMonths, alunoCursoCalcDates, alunoCursoFinalDate, alunoCursoMonthlyValue, alunoCursoTurnosHorarios, openBase64Pdf } from "../../../functions";
import dayjs from "dayjs";
import { alunoApi, eventoApi } from "../../../services";
import Alert from "../../../helpers/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";


export type FormGroupType = {
  form: AlunoCursoFormType;
  isLoading: boolean;
  setAlunoCurso: (alunoCursoIndex: number, alunoCurso: Partial<AlunoCurso>) => any;
  removeAlunoCurso: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
  addAlunoCurso: (alunoCurso?: AlunoCurso) => any;
  getAlunoCursoErrors: (alunoCursoIndex: number) => null | AlunoCursoErrors;
}

export default function AlunoCursoForm() {
  const navigate = useNavigate();
  const alunos = useAlunos();
  const cursos = useCursos();
  const { materiais, materiaisAsSelectOptions } = useMateriais();
  const { matricula } = useParams();

  const { step, prevStep, nextStep } = useStep();
  const { form, errors, setForm, setLoading, loading, validateWithZod } = useForm({
    initialData: {
      aluno_cursos: [{}]
    } as AlunoCursoFormType,
  });
  const isLoading = loading || alunos.loading || cursos.loading;

  useEffect(() => {
    if (!matricula) return;
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function load() {
    const { data } = await alunoApi.alunoCursoFind(matricula);
    setForm({
      ...form,
      aluno_cursos: data.map(alunoCurso => typeof alunoCurso.data === 'string' ? JSON.parse(alunoCurso.data).original_data : (alunoCurso.data as any).original_data),
    })
  }

  function addAlunoCurso(alunoCurso?: Partial<AlunoCurso>) {
    setForm({
      ...form,
      aluno_cursos: [
        ...(form.aluno_cursos ?? []), 
        (alunoCurso ?? {} as AlunoCurso)
      ]
    });
  }

  function removeAlunoCurso(i: number, alunoCurso: Partial<AlunoCurso>) {
    setForm(form => ({
      ...form,
      aluno_cursos: form.aluno_cursos!.filter((item, j) => j !== i)
    }));
  }

  function setAlunoCurso(alunoCursoIndex: number, alunoCurso?: Partial<AlunoCurso>) {
    setForm((form) => ({
      ...form,
      aluno_cursos: form.aluno_cursos!.map((item, i) => i === alunoCursoIndex ? {...item, ...(alunoCurso ?? {}) } : item),
    }));
  }

  function getAlunoCursoErrors(alunoCursoIndex: number): null | AlunoCursoErrors {
    if (!(errors?.aluno_cursos as any)?.hasOwnProperty(alunoCursoIndex)) return null;
    return (errors?.aluno_cursos as any)[alunoCursoIndex] ?? null;
  }
  
  function validateForm() {
    const validationRules = z.object({
      aluno_cursos: z.object({
        aluno_id: z.number().min(1),
        curso_id: z.number().min(1),
        professor_temp: z.object({}),
        dias_semana_temp: z.array(z.any()).nonempty(),
        horarios: z.array(z.any()).nonempty(),
        planos: z.array(z.string()).nonempty(),
        data_inicio: z.string().min(1),
        pagamento_dia: z.number().min(1),
        pagamento_forma: z.string().min(1),
        pagamento_tipo_notificacao: z.string().min(1),
      }).array()
    });

    return validateWithZod(validationRules);
  }

  function goToStep1() {
    prevStep();
  }

  function goToStep2() {
    loadAllCursosDates();
    loadAllAlunoCursosPaymentDates();
    setTimeout(() => {
      // console.clear();
      // console.log('Dados do formulário:');
      console.log(form);
    }, 5000);
    nextStep();
  }

  function loadAllAlunoCursosPaymentDates() {
    form.aluno_cursos!.forEach((alunoCurso, i) => {
      const meses_pagamento = loadAlunoCursoPaymentDate(alunoCurso);
      setAlunoCurso(i, { ...alunoCurso, meses_pagamento });
    });
  }

  function loadAlunoCursoPaymentDate(alunoCurso: Partial<AlunoCurso>) {
    if (!alunoCurso.data_inicio || !alunoCurso.pagamento_dia) return [];

    const months: string[] = [];

    let month = dayjs(alunoCurso.data_inicio);
    month.date(alunoCurso.pagamento_dia);

    Array(alunoCursoAmountOfMonths(alunoCurso)).fill(0).forEach(() => {
        months.push(month.format('YYYY-MM-DD'));
        month = month.add(1, 'month');
    });

    return months.map(paymentDate => {
      const paymentDateMonth = Number(dayjs(paymentDate).month()) + 1;
      const paymentDateYear = Number(dayjs(paymentDate).year());
      const targetDay = Number(alunoCurso.pagamento_dia);

      const currentDate = dayjs();
      const targetDate = dayjs(`${paymentDateYear}-${paymentDateMonth}-${targetDay}`);

      if (currentDate.isAfter(targetDate)) {
        return paymentDate;
      }

      return targetDate.format('YYYY-MM-DD');

    });
  }

  async function loadAllCursosDates() {
    form.aluno_cursos!.forEach((alunoCurso, i) => {
      const promise = loadCursoDates(alunoCurso);
      setAlunoCurso(i, { ...alunoCurso, datas_aulas_promise: promise });
      promise.then(dates => {
        setAlunoCurso(i, { ...alunoCurso, datas_aulas_promise: undefined, datas_aulas: dates });
      });
    });
  }

  async function loadCursoDates(ac: Partial<AlunoCurso>) {
    let dates = alunoCursoCalcDates(ac).sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1);

    const events = await eventoApi.loadCourseEvents([ac.curso_id!], dates[0].date, dates[dates.length - 1].date);

    for (let i = 0; i < dates.length; i++) {
      if (i > 0) {
        if (dayjs(dates[i].date).isSame(dayjs(dates[i - 1].date)) || dayjs(dates[i].date).isBefore(dayjs(dates[i - 1].date))) {
          dates[i].date = dayjs(dates[i - 1].date).add(1, 'week').format('YYYY-MM-DD');
        }
      }

      for (const event of events) {
        const eventStart = dayjs(event.inicio.split(' ')[0]);
        const eventEnd = dayjs(event.fim.split(' ')[0]);

        while (
          !event.considerar_contagem_aulas &&
          (eventStart.isSame(dayjs(dates[i].date)) || eventStart.isBefore(dayjs(dates[i].date)))
          && (eventEnd.isSame(dayjs(dates[i].date)) || eventEnd.isAfter(dayjs(dates[i].date)))
        ) {
          dates[i].date = dayjs(dates[i].date).add(1, 'week').format('YYYY-MM-DD');
        }
      }
    }

    // Order events dates
    dates = dates.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1);

    return dates;
  }

  function onNext() {
    if (! validateForm()) return;

    goToStep2();
  }

  function makeRequestData() {
    return {
      aluno_cursos: (form.dobrar_cursos ? form.aluno_cursos : form.aluno_cursos?.filter(item => !item.dobra))!.map(alunoCurso => {
          return {
              original_data: alunoCurso,
              ...alunoCurso,
              materiaisRepasse: alunoCurso.materiaisRepasse?.filter(m => m.material_id).map(m => ({ ...m, valor: getValorMaterial(m.material_id) })) ?? [],
              datas_aulas: alunoCurso.datas_aulas?.filter(da => da.date),
              meses_pagamento: alunoCurso.meses_pagamento,
              valor_mes_original: alunoCursoMonthlyValue(alunoCurso, true, { formatar: true }),
              valor_mes: `${alunoCursoMonthlyValue(alunoCurso, true, { formatar: false })}`,
              observacao: Number(alunoCurso.porcentagem_desconto) > 0 ? (
                  `${alunoCursoMonthlyValue(alunoCurso, false, { formatar: true })} - ${alunoCurso.porcentagem_desconto}% = ${alunoCursoMonthlyValue(alunoCurso, true,  { formatar: true })}`
              ) : '',
              curso_nome: alunoCurso.curso?.descricao,
              curso_plano: alunoCurso.planos?.join(', '),
              dias_semana: alunoCursoTurnosHorarios(alunoCurso).filter((horario) => {
                  return horario.value === alunoCurso.horarios?.find((h) => h.salas_turnos_id === horario.value)?.salas_turnos_id
              }).map((horario) => horario.label).join(', '),

              dias_semana_list: alunoCursoTurnosHorarios(alunoCurso).filter((horario) => {
                  return horario.value === alunoCurso.horarios?.find((h) => h.salas_turnos_id === horario.value)?.salas_turnos_id
              }).map((horario) => horario.label.trim().substr(0, 3)),

              professor_nome: alunoCurso.professor_temp?.nome,
              inicio_plano: alunoCurso.data_inicio!.split('-').reverse().join('/'),
              data_fim: alunoCursoFinalDate(alunoCurso).split('/').reverse().join('-'),
              fim_plano: alunoCursoFinalDate(alunoCurso),
              curso: undefined,
              professor_temp: undefined,
          }
      }),
    }
  }

  const responsavelCPF = form.aluno_cursos?.find(ac => !!ac.aluno)?.aluno?.responsavel_cpf;


  async function onPreviewContract() {
    Alert.await('Gerando pre-visualização do contrato...');
    try { 
      const { data } = await alunoApi.downloadPreview(makeRequestData());
      Alert.close();
      openBase64Pdf(data);
    } catch (error) {
      console.log(error);
      Alert.error((error as any)?.message ?? 'Erro ao gerar pre-visualização do contrato. Tente novamente mais tarde.');
    }
  }

  async function onSave() {
    const confirm  = await Alert.confirm('A matrícula será salva. O contrato poderá ser enviado a qualquer momento, porém, os dados não poderão ser alterados. Tem certeza que deseja continuar?');
    if (! confirm) return;
    setLoading(true);
    try {
      await alunoApi.alunoCurso(makeRequestData());
      setLoading(false);
      Alert.success('Matrícula salva com sucesso');
      navigate('/alunos-cursos');
    } catch(e) {
        setLoading(false);
        return Alert.error('Falha ao salvar matrícula');
    }
  }

  async function onSendContract() {
    if (!matricula) return;
    const confirm  = await Alert.confirm('O contrato será enviado para assinatura. Tem certeza que deseja continuar?');
    if (! confirm) return;
    setLoading(true);
    try {
        await alunoApi.sendContract(matricula, makeRequestData());
        setLoading(false);
        Alert.success('Contrato enviado com sucesso.');
        navigate('/alunos-cursos');
    } catch(e) {
        setLoading(false);
        return Alert.error('Falha enviar contrato');
    }
  }

  async function onAddPaymentDate(i: number, alunoCurso: Partial<AlunoCurso>) {
    setAlunoCurso(i, {
      ...alunoCurso,
      meses_pagamento: [
        ...(alunoCurso.meses_pagamento ?? []),
        ''
      ]
    });
  }

  async function onRemovePaymentDate(i: number, alunoCurso: Partial<AlunoCurso>) {
    setAlunoCurso(i, {
      ...alunoCurso,
      meses_pagamento: alunoCurso.meses_pagamento?.slice(0, -1)
    });
  }

  async function onAddClassDate(i: number, alunoCurso: Partial<AlunoCurso>) {
    setAlunoCurso(i, {
      ...alunoCurso,
      datas_aulas: [
        ...(alunoCurso.datas_aulas ?? []),
        { date: '', title: '' }
      ]
    });
  }

  async function onRemoveClassDate(i: number, alunoCurso: Partial<AlunoCurso>) {
    setAlunoCurso(i, {
      ...alunoCurso,
      datas_aulas: alunoCurso.datas_aulas?.slice(0, -1)
    });
  }

  function onAddMaterial(i: number, alunoCurso: Partial<AlunoCurso>) {
    setAlunoCurso(i, {
      ...alunoCurso,
      materiaisRepasse: [
        ...(alunoCurso.materiaisRepasse ?? []),
        { aluno_curso_id: alunoCurso.id, quem_paga: 'espaco'  } as any]
    });
  }

  function onRemoveMaterial(i: number, alunoCursoIndex: number,alunoCurso: Partial<AlunoCurso>) {
    const newMateriais = alunoCurso.materiaisRepasse?.filter((material, index) => i != index);
    setAlunoCurso(alunoCursoIndex, {
      ...alunoCurso,
      materiaisRepasse: newMateriais
    });
  }

  function getValorMaterial(materialId: number) {
    const material = materiais.find((m) => m.id === materialId);
    if (! material) return 0;
    return material?.valor ?? 0;
  }
  
  return (
    <PageContainer>
      <Step step={step} steps={[
        <Step1
          matricula={matricula}
          aluno={{
            ...alunos,
            responsavelCPF
          }}
          curso={cursos}
          materiaisAsSelectOptions={materiaisAsSelectOptions}
          events={{
            onNext,
            onAddMaterial,
            onRemoveMaterial,
            getValorMaterial
          }}
          form={{ 
            form,
            isLoading,
            addAlunoCurso,
            setAlunoCurso,
            getAlunoCursoErrors,
            removeAlunoCurso
          }}
        />,

        <Step2 
          matricula={matricula}
          events={{
            onReturn: goToStep1,
            onPreviewContract,
            onSave,
            onSendContract,
            onAddPaymentDate,
            onRemovePaymentDate,
            onAddClassDate,
            onRemoveClassDate
          }}
          form={{ 
            form,
            isLoading,
            addAlunoCurso,
            setAlunoCurso,
            getAlunoCursoErrors,
            removeAlunoCurso
          }}
        />
      ]} />

      {/* <div>
        {JSON.stringify(form.aluno_cursos![0].meses_pagamento)}
        {JSON.stringify(form.aluno_cursos![0].datas_aulas)}
      </div> */}
    </PageContainer>
  );
}