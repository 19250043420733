import { Button, ClausulaForm, ListPageLayout, Modal } from "../../components";
import { useModal } from "../../hooks";
import { alunoApi } from "../../services";

export default function AlunosCursos() {
  const {
    open: openClausulaModal,
    close: closeClausulaModal,
    modalProps,
  } = useModal();

  return (
    <div>
      <ListPageLayout
        title="Contratos"
        creation={{ url: "/alunos-cursos/cadastro" }}
        edition={{
          url: (item: any) => `/alunos-cursos/cadastro/${item.matricula}`,
        }}
        actions={
          <>
            <Button className="mr-4" onClick={openClausulaModal} type="button">
              Nova Cláusula
            </Button>
          </>
        }
        table={{
          loader: alunoApi.alunoCursoList as any,
          columns: [
            { title: "Matrícula", field: "matricula" },
            { title: "Responsável Financeiro", field: "responsavel_nome" },
            { title: "Aluno(s)", field: "nomes_alunos" },
          ],
        }}
      />

      <Modal {...modalProps} size="xl" title="Nova Cláusula">
        <ClausulaForm onSubmitSuccess={closeClausulaModal} />
      </Modal>
    </div>
  );
}
